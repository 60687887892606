search<template>
  <div class='list'>
    <div class="globle_border">
      <div class="search">
        <back></back>
        <div>
          <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false
          , multiple: true }" @change="onSearch" clearable placeholder="选择分类"></el-cascader>
          <!-- <el-select v-model="purchaseStatus" placeholder="选择分类" clearable @change="onSearch">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->

          <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="barCode" placeholder="请搜索条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          <el-input v-model="brand" placeholder="请搜索品牌" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        </div>
      </div>
      <div style="margin-bottom: 20px;">进货套餐名称：<span>{{packageName}}</span></div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column> -->
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip width="200"></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格"></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column prop="purchaseBox" label="建议入箱数" min-width="100"></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
          <el-table-column prop="validityPeriod" label="保质期(天)"  width="100"></el-table-column>
          <!-- <el-table-column prop="createdTime" label="下单时间"></el-table-column>    -->

        </el-table>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <el-button type='primary' @click="handleJoin">进入采购</el-button>

      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>

export default {

  data() {
    return {
      dialogVisible: false,
      submitLoding: false,
      options: [],
      brand: '',
      barCode: '',
      packageName: this.$route.query.packageName,
      supplierName: '',
      productName: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      value: '',
      shopId: '',
      selectedOptions: [],
    }
  },
  created() {
    this.shopId = this.$store.state.userInfo.shopId;
    this.getPurchase_cart()
    this.getCategoryLIst()
  },
  methods: {
    // 进入采购
    handelOpen(code) {
      this.$router.push({
        path: '/procure/detail/procurementDetail',
        query: {
          id: this.$route.query.id,
          packageName: this.$route.query.packageName,
          code:code
        }
      })
    },
    // 加入进货单
    handleJoin() {
      // 提示确定要加入吗
        this.$axios.post(this.$api.saveInPurchaseProduct, {
          id: parseInt(this.$route.query.id),
          shopId: this.shopId
        }).then((res) => {
          if (res.data.code == 100) {
            // this.$message({
            //   type: 'success',
            //   message: '加入进货单成功',
            // });
            // 返回上一层
            let code = res.data.result
            this.handelOpen(code)
          }
        })
      
    },
    // 分类
    getCategoryLIst() {
      this.$axios.get(this.$api.selectProductCategory, {
        params: {
          id: this.$route.query.id,
        },
      }).then((res) => {
        this.options = res.data.result
        this.options.map((item) => {
          if (item.children.length == 0) {
            item.children = null
          }
        })
      }).catch(() => {

      });
    },
    // 商超列表
    getPurchase_cart() {
      this.$axios.post(this.$api.selectPurchasePackageProduct, {
        // shopId: this.shopId || null,
        barCode: this.barCode || null,
        brand: this.brand || null,
        supplierName: this.supplierName || null,
        categoryIds: this.value || [],
        existingSuppliers: '1' || null,
        packageId: parseInt(this.$route.query.id),
        page: this.currentPage,
        pageSize: this.pageSize,
      }).then((res) => {
        this.tableData = res.data.result.list;
        this.totalItemsCount = res.data.result.totalCount;
      }).catch(() => {
      });
    },

    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getPurchase_cart()
      // console.log(this.value);
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e;
      this.getPurchase_cart();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e;
      this.getPurchase_cart();
    },




  },
}
</script>

<style scoped lang='scss'>
.list {
  .search {
    ::v-deep .back {
      padding: 0 !important;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    .el-range-editor {
      width: 300px;
      margin-bottom: 20px;
    }

    .el-select,
    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .red {
    color: red;
  }

  .grey {
    color: grey;
  }
}
</style>